import React from "react";
import styled from "styled-components";
import { withPage } from "../PageContainer";
import { connect } from "react-redux";
import ActionCreator from "../ActionCreator";
import { StaticImage } from "../Components/StaticImage";
import { HeaderFontCss, ParagraphFontCss } from "../Utils/CssStyleTemplateUtil";
import { s, d } from "../Utils/Lang";
import * as Widget from "../Components/Widget";
import { isDesktop, isPhone } from "../Utils/WindowUtil";

const SERVICE_STEPS = [
  {
    title: "product-service-step1_title",
    mainIcon: "icon-consult.png",
    from: "product-service-step1_from",
    to: "product-service-step1_to",
    fromIcon: "icon-need.png",
    toIcon: "icon-plan.png",
    description: "product-service-step1_description",

    link: "https://consult.revtel.tech/",
  },
  {
    title: "product-service-step2_title",
    mainIcon: "icon-developement.png",
    from: "product-service-step2_from",
    to: "product-service-step2_to",
    fromIcon: "icon-plan.png",
    toIcon: "icon-product.png",
    description: "product-service-step2_description",
    link: "https://consult.revtel.tech/",
  },
  {
    title: "product-service-step3_title",
    mainIcon: "icon-business.png",
    from: "product-service-step3_from",
    to: "product-service-step3_to",
    fromIcon: "icon-product.png",
    toIcon: "icon-success.png",
    description: "product-service-step3_description",
    link: "https://consult.revtel.tech/",
  },
];

class ProductPage extends React.Component {
  render() {
    let { navActions } = this.props;
    return (
      <Wrapper>
        <div className="introduction-section">
          <h1 className="title" style={{ fontSize: 26, marginBottom: 16 }}>
            {s("product-title")}
          </h1>
          <p>{s("product-description")}</p>
          <br />
          <h2>{s("tech-good")}</h2>
          <p style={{ verticalAlign: "middle" }}>
            <h3
              style={{
                fontWeight: "bold",
                fontSize: "1rem",
                display: "inline-block",
              }}
            >
              FE：&nbsp;&nbsp;
            </h3>
            <h4 style={{ display: "inline-block" }}>ReactJs / React Native</h4>
          </p>
          <br />
          <p style={{ verticalAlign: "middle" }}>
            <h3
              style={{
                fontWeight: "bold",
                fontSize: "1rem",
                display: "inline-block",
              }}
            >
              BE：&nbsp;&nbsp;
            </h3>
            <h4 style={{ display: "inline-block" }}>
              Python / Node.js / AWS Lambda
            </h4>
          </p>
          <br />

          <p style={{ verticalAlign: "middle" }}>
            <h3
              style={{
                fontWeight: "bold",
                fontSize: "1rem",
                display: "inline-block",
              }}
            >
              Connectivity：&nbsp;&nbsp;
            </h3>
            <h4 style={{ display: "inline-block" }}>NFC / Bluetooth / BLE</h4>
          </p>
          <br />
          <p style={{ verticalAlign: "middle" }}>
            <h3
              style={{
                fontWeight: "bold",
                fontSize: "1rem",
                display: "inline-block",
              }}
            >
              Web3：&nbsp;&nbsp;
            </h3>
            <h4 style={{ display: "inline-block" }}>
              Cold Wallet / Smart Contract
            </h4>
          </p>
        </div>
        <div className="service-section">
          <div className="top-linear-gradient-container">
            <div className="absolute-arrows-icon-step1">
              <StaticImage path={"/product/"} name="icon-arrows.png" />
            </div>
            <div className="absolute-arrows-icon-step2">
              <StaticImage path={"/product/"} name="icon-arrows.png" />
            </div>
            <div className="absolute-arrows-icon-step3">
              <StaticImage path={"/product/"} name="icon-arrows.png" />
            </div>
          </div>
          <div className="left-linear-gradient-container">
            <div className="absolute-arrows-icon-step1">
              <StaticImage path={"/product/"} name="icon-arrows.png" />
            </div>
            <div className="absolute-arrows-icon-step2">
              <StaticImage path={"/product/"} name="icon-arrows.png" />
            </div>
            <div className="absolute-arrows-icon-step3">
              <StaticImage path={"/product/"} name="icon-arrows.png" />
            </div>
          </div>
          <div className="item-container">
            {SERVICE_STEPS.map((item, idx) => (
              <ServiceItem item={item} idx={idx} key={idx} />
            ))}
          </div>
          <div className="bottom-linear-gradient-container" />
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  color: #4e4e4e;

  .title {
    font-size: 35px;
    line-height: 35px;
    letter-spacing: 0.95px;
  }

  .introduction-section {
    background-color: #f1f1f1;
    padding: 30px;
    color: #4e4e4e;
    display: flex;
    flex-direction: column;
    flex-basis: 360px;
    flex-grow: 0;
    flex-shrink: 0;

    & h2 {
      font-size: 26px;
      ${HeaderFontCss}
      margin-bottom: 20px;
    }

    & p {
      font-size: 14px;
      ${ParagraphFontCss}
      white-space: pre-wrap;
      text-align: justify;
      line-height: 20px;
      letter-spacing: 0.34px;
    }

    & button {
      margin-top: 40px;
      align-self: flex-start;
    }
  }

  .service-section {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    & .top-linear-gradient-container {
      position: relative;
      background-image: linear-gradient(
        90deg,
        #fdce82 17%,
        #faa34d 57%,
        #f36a26 90%
      );
      width: 100%;
      height: 20px;
    }

    & .bottom-linear-gradient-container {
      background-image: linear-gradient(
        90deg,
        #fdce82 17%,
        #faa34d 57%,
        #f36a26 90%
      );
      width: 100%;
      height: 5px;
    }

    & .left-linear-gradient-container {
      display: none;
      position: relative;
      background-image: linear-gradient(
        180deg,
        #fdce82 17%,
        #faa34d 57%,
        #f36a26 90%
      );
      width: 20px;
      height: auto;
    }

    & .absolute-arrows-icon-step1 {
      position: absolute;
      left: calc(33.33% - 49px);
      width: 49px;
      height: 20px;
    }

    & .absolute-arrows-icon-step2 {
      position: absolute;
      left: calc(66.66% - 49px);
      width: 49px;
      height: 20px;
    }

    & .absolute-arrows-icon-step3 {
      position: absolute;
      left: calc(99.99% - 49px);
      width: 49px;
      height: 20px;
    }

    & .item-container {
      flex: 1;
      display: flex;
    }
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;
    .introduction-section {
      padding: 30px 40px 40px;
      flex-basis: initial;

      flex-grow: 1;
      & h2 {
        ${HeaderFontCss}
        letter-spacing: 2px;
        text-align: left;
      }

      & p {
        font-size: 14px;
        ${ParagraphFontCss}
      }

      & button {
        align-self: center;
      }
    }

    .service-section {
      & .top-linear-gradient-container {
      }

      & .bottom-linear-gradient-container {
      }

      & .left-linear-gradient-container {
      }

      & .item-container {
      }
    }
  }

  @media screen and (max-width: 500px) {
    .logo-image-container {
      display: none;
    }
    .introduction-section {
      padding: 30px 25px 50px;
      & h2 {
        ${HeaderFontCss}
        letter-spacing: 2px;
        font-size: 26px;
        margin-bottom: 15px;
        text-align: left;
      }
      & button {
        margin-top: 30px;
        font-size: 14px;
        align-self: flex-start;
      }
    }
    .service-section {
      display: flex;
      flex-direction: row;

      & .top-linear-gradient-container {
        display: none;
      }

      & .bottom-linear-gradient-container {
        display: none;
      }

      & .left-linear-gradient-container {
        display: block;
      }

      & .absolute-arrows-icon-step1 {
        top: calc(33.33% - 25px);
        transform: rotate(90deg);
        left: -14.5px;
        width: 49px;
        height: 20px;
      }

      & .absolute-arrows-icon-step2 {
        top: calc(66.66% - 25px);
        transform: rotate(90deg);
        left: -14.5px;
        width: 49px;
        height: 20px;
      }

      & .absolute-arrows-icon-step3 {
        bottom: calc(0px + 28px);
        transform: rotate(90deg);
        left: -14.5px;
        width: 49px;
        height: 20px;
      }

      & .item-container {
        flex-direction: column;
      }
    }
  }
`;

class ServiceItem extends React.Component {
  render() {
    let { item } = this.props;

    return (
      <ItemWrapper
        cursor={item.link ? "pointer" : "default"}
        onClick={() => window.open(item.link)}
      >
        <div className="main-icon-container">
          <StaticImage path={"/product/"} name={item.mainIcon} />
        </div>
        <h4>{s(item.title)}</h4>
        <div className="flow-container">
          <Widget.Row justify="space-between" align="flex-end">
            <Widget.Col>
              <div className="step-icon-container">
                <StaticImage
                  path={"/product/"}
                  name={item.fromIcon}
                  style={{ width: 50, height: 50 }}
                />
              </div>
              <h5>{s(item.from)}</h5>
            </Widget.Col>
            <StaticImage
              path={"/product/"}
              name="icon-arrow.png"
              style={{ width: 25, height: 25, marginRight: 10, marginLeft: 10 }}
            />
            <Widget.Col>
              <div className="step-icon-container">
                <StaticImage
                  path={"/product/"}
                  name={item.toIcon}
                  style={{ width: 50, height: 50 }}
                />
              </div>
              <h5>{s(item.to)}</h5>
            </Widget.Col>
          </Widget.Row>
        </div>
        <div className="description-container">
          <p>{s(item.description)}</p>
        </div>
      </ItemWrapper>
    );
  }
}

const ItemWrapper = styled.div`
  border-right: 1px solid #f1f1f1;
  max-width: 33.33%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 30px;
  cursor: ${props => props.cursor};

  & .main-icon-container {
    width: 70px;
    height: 70px;
    margin-bottom: 15px;
  }

  & .step-icon-container {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  & .flow-container {
    width: 100%;
    border-top: 1px solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
    padding: 30px 0px;
    display: flex;
    justify-content: center;
  }

  & .description-container {
    padding: 20px 0px;
  }

  & h4 {
    ${HeaderFontCss}
    font-size: 26px;
    color: #4e4e4e;
    letter-spacing: 0.82px;
    margin-bottom: 54px;
  }

  & h5 {
    ${HeaderFontCss}
    text-align: center;
    font-size: 16px;
    color: #4e4e4e;
    letter-spacing: 0.32px;

    @media screen and (max-width: 1080px) {
      width: 44px;
    }
    @media screen and (max-width: 500px) {
      width: 100%;
    }
  }

  & p {
    padding: 20px 0px;
    font-size: 14px;
    color: #4e4e4e;
    letter-spacing: 0.4px;
    line-height: 20px;
    ${ParagraphFontCss}
  }

  @media screen and (max-width: 500px) {
    max-width: 100%;
    border-bottom: 1px solid #f1f1f1;
  }
`;

export default withPage(connect(null, ActionCreator)(ProductPage));
